<template>
    <div align="center">
        <br>
        <div align="center">
            <span class="text-h4">Allega documenti alla Quotazione</span>
        </div>
        <br><br>

        <div class="text-center justify-center q-pa-md q-gutter-sm fixed-center"
            v-show="!is_loading"
            style="z-index: 1000;">

            <q-spinner-hourglass
                size="4em"
            />

        </div>

        <div class="row justify-center">

            <div class="col-12 col-md-4" align="left">
                <q-list  separator>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Area:</q-item-label>
                            <q-item-label caption>{{quotazione.area}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Prodotto:</q-item-label>
                            <q-item-label caption>{{quotazione.id_prodotto}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Numero preventivo:</q-item-label>
                            <q-item-label caption>{{quotazione.guid_preventivo}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Descrizione preventivo:</q-item-label>
                            <q-item-label caption>{{quotazione.nominativo}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Stato della quotazione:</q-item-label>
                            <q-item-label caption>{{getStato}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Unit&agrave; Operativa:</q-item-label>
                            <q-item-label caption>{{quotazione.unita_operativa}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Operatore:</q-item-label>
                            <q-item-label caption>{{quotazione.operatore}}</q-item-label>
                        </q-item-section>
                    </q-item>
                </q-list>
            </div>

            <div class="col-12 col-md-6 q-px-md" align="left">
                <h5 class="q-mt-sm q-mb-sm q-pt-sm">Documenti gia allegati</h5>

                <div v-if="elenco_documenti.length === 0">Nessun documento allegato</div>

                <div v-if="elenco_documenti.length > 0">

                    <q-list bordered  separator>
                        <q-item tag="label" v-ripple  v-for="(documento,index) in elenco_documenti" v-bind:key="index" @click.native="onScaricaDocumento(documento)">
                            <q-item-section>
                                <q-item-label>Nome del documento</q-item-label>
                                <q-item-label caption>{{documento.label}}</q-item-label>
                            </q-item-section>

                            <q-item-section>
                                <q-item-label>Dimensione del file</q-item-label>
                                <q-item-label caption>{{documento.file_size}} bytes</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>Info documento</q-item-label>
                                <q-item-label caption>{{documento.value[0].descrizione_documento}}</q-item-label>
                            </q-item-section>
                            <q-item-section avatar>
                                <q-avatar color="orange-3" text-color="black" icon="mdi-download" />
                            </q-item-section>
                        </q-item>
                    </q-list>

                </div>
            </div>

            <div class="col-12 col-md-12 q-px-md" align="center">
                <hr>
                <br>
            </div>

            <div class="col-12 col-md-6 q-px-md" align="center">

                <q-uploader
                    ref="UploadObject"
                    url="https://localhost:1000"
                    color="teal"
                    label="Trascina QUI i documenti da inviare"
                    multiple
                    hide-upload-btn
                    :filter="checkFileType"
                    style="max-width: 800px; width: 100%; min-height: 300px; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);"
                    @rejected="onRejected"
                    @added="onAggiungiFileAllegati"
                    @removed="onRimuoviFileAllegati"
                    @uploading="onUploading"
                />
            </div>
        </div>

        <br><br>
        <hr>
        <div class="row" align="center">
            <div class="col-12 col-md-6" align="center">
                <QQButton label="INDIETRO" color="blue-grey" icon="undo" size="md"
                    :disable="!is_loading"
                    @click.native="onIndietroClicked"
                />
            </div>
            <div class="col-12 col-md-6" align="center">
                <QQButton label="ALLEGA" color="blue-grey" icon="mdi-content-save-outline" size="md" icon_align="right"
                    :disable="!is_loading"
                    @click.native="onUploading"
                />
            </div>
        </div>

        <br><br><br><br>

    </div>
</template>

<script>
    import QQButton from "@/components/QQButton.vue";
    import { mapFields} from "vuex-map-fields";
    import { mapState, mapActions } from "vuex";
    import commonLib from "@/libs/commonLib.js";
    import axios from 'axios';

    export default {
        name: "AllegaDocumenti",
        data() {
            return {
                quotazione: {},
                elenco_documenti: [],
                documenti_allegati: [],
                totale_file_allegati: 0,
                dati_preventivo: {},
                is_loading: true
            }
        },
        components: {
            QQButton
        },
        computed: {
            ...mapFields('gestioneQuotazioni', [
                "idquotazione"
            ]),
            ...mapState({
                formPreventivo: state => state.formPreventivo,
                // elenco_documenti: state => state.gestioneDocumenti.elenco_documenti
            }),
            getStato() {
                if (commonLib.isEmpty(this.quotazione)) return "";

                return this.quotazione.stato.replaceAll("_"," ");
            }
        },
        methods: {
            ...mapActions({
                fetchDatiQuotazione: "gestioneQuotazioni/fetchDatiQuotazione",
                fetchElencoDocumentiDaPreventivo: "gestioneDocumenti/fetchElencoDocumentiDaPreventivo",
                fetchDownloadUrl: "gestioneDocumenti/fetchDownloadUrl",
                fetchUploadUrl: "gestioneDocumenti/fetchUploadUrl",
                fetchDatiPreventivo: "formPreventivo/fetchDatiPreventivo"
            }),
            checkFileType(files) {
                let status = (files.filter(file => file.type === 'application/pdf').length === 1) ||
                             (files.filter(file => file.type === 'application/x-compressed').length === 1) ||
                             (files.filter(file => file.type === 'application/x-zip-compressed').length === 1) ||
                             (files.filter(file => file.type === 'image/png').length === 1) ||
                             (files.filter(file => file.type === 'image/jpeg').length === 1) ||
                             (files.filter(file => file.type === 'image/gif').length === 1) ||
                             (files.filter(file => file.type === 'application/vnd.fdf').length === 1);
                if (status) {
                    return files.filter(file => file.type === files[0].type);
                }

                return [];
            },
            onRejected () {
                this.$q.dialog({
                    title: 'Attenzione',
                    message: 'Il tipo di documento allegato può essere solo nel formato ZIP,PDF,FDF,PNG,JPG,JPEG,GIF. Gli altri formati non sono accettati per questioni di sicurezza.'
                });
            },
            onAggiungiFileAllegati(files) {
                this.totale_file_allegati += files.length;

                this.elenco_documenti.forEach(p => {
                    p.is_checked = false;
                });

                var name = files[0].name;
                var valori = [
                    {
                        "descrizione_documento": "Documento aggiuntivo",
                        "is_checked": true
                    }
                ];

                var create_at = this.formPreventivo.preventivo.create_at;
                //console.log("create_at:",create_at);



                var elements = create_at.split("/");
                var documento = {
                    "label": name,
                    "file_size": files[0].size,
                    "tipo_documento": files[0].type,
                    "value": valori,
                    "AnnoCreazione": elements[2],
                    "MeseCreazione": elements[1]
                }

                //console.log("documento:",documento);

                this.documenti_allegati.push(documento);
            },
            onRimuoviFileAllegati() {

            },
            async onScaricaDocumento(documento) {
                var NumeroPreventivo = this.formPreventivo.preventivo.id;
                var anno = documento.annoCreazione;
                var mese = documento.meseCreazione+"";

                mese = mese.padStart(2,"0");

                var Filename = "documenti/preventivi/"+anno+"/"+mese+"/"+NumeroPreventivo+"/"+documento.label;

                // Richiese url per il download
                var url = await this.fetchDownloadUrl(Filename);

                window.location = url;
            },
            async onUploading() {
                this.files = this.$refs.UploadObject.files;

                if (this.files.length === 0) {
                    this.$q.dialog({
                            title: 'Attenzione',
                            message:"Non sono presenti documenti da allegare"
                        });
                    return;
                }

                this.is_loading = false;

                for( var i = 0; i < this.files.length; i++ ) {
                    let file = this.files[i];
                    let formData = new FormData();
                    formData.append('files[' + i + ']', file);

                    var documento = this.documenti_allegati[i];
                    //this.elenco_documenti.push(documento);

                    // recupera url da S3
                    let url = await this.fetchUploadUrl({IDPreventivo:this.quotazione.guid_preventivo,
                                                            Filename:file.name,
                                                            InfoDocumento:documento});

                    if (url.error !== "") {
                        this.$q.dialog({
                            title: 'Attenzione',
                            message: url.error
                        });

                        return;
                    }

                    await axios.put(
                        url.data,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }).then(function(){
                            //console.log('SUCCESS!!');
                        }).catch(function(){
                            //console.log('FAILURE!!');
                        });

                }

                // Cancella la lista dei documenti allegati
                this.$refs.UploadObject.removeUploadedFiles();
                this.$refs.UploadObject.removeQueuedFiles();

                this.elenco_documenti = await this.fetchElencoDocumentiDaPreventivo(this.quotazione.guid_preventivo);
                this.elenco_documenti = this.elenco_documenti.data.result;

                this.is_loading = true;
            },
            onIndietroClicked() {
                this.$router.push({name : "Quotazioni.MenuGestioneQuotazioni"});
            }
        },
        async mounted() {
            this.quotazione = await this.fetchDatiQuotazione(this.idquotazione);
            var elenco_documenti = await this.fetchElencoDocumentiDaPreventivo(this.quotazione.guid_preventivo);
            await this.fetchDatiPreventivo(this.quotazione.guid_preventivo);
            this.elenco_documenti = elenco_documenti.data.result;

            //console.log("elenco_documenti:",this.elenco_documenti);
           // console.log("formPreventivo:",this.formPreventivo);
        }
    }

</script>
